
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { scrollIntoViewIfNeeded } from '@/utils/helpers';
import '@/validation-rules';

const DEFAULT_DELIVERY_INFORMATION = {
	type: 'stadium',
	extra: ''
};

@Component<DeliveryStadium>({
	components: {
		ValidationProvider
	}
})
export default class DeliveryStadium extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private restaurantDeliveryInfo!: StadiumDelivery;
	@Prop({ type: Object, required: false, default: () => {} }) private deliveryInformation!: CheckoutDeliveryInfo;

	private delivery: CheckoutDeliveryInfo = { ...DEFAULT_DELIVERY_INFORMATION };
	private sections: string[] = [];
	private rows: string[] = [];
	private seats: string[] = [];

	/**
	* Set stadium sections, rows, seats
	*
	* @return {void}
	*/
	private created(): void {
		this.setSelectors();
	}

	private mounted(): void {
		if(this.deliveryInformation) {
			this.delivery = this.deliveryInformation;
			this.delivery.type = 'stadium';
		}
	}

	/**
	 * Set if searchable or not
	 *
	 * @param {any[]} numbers
	 * @return {boolean}
	 */
	private rangeDiffAbove50(numbers: any[]): boolean {
		const min = Math.min.apply(null, numbers);
		const max = Math.max.apply(null, numbers);
		if(max - min > 50) {
			return true;
		}
		return false;
	}

	/**
	* Send event to the parent to update the delivery
	* information in the vuex store
	*
	* @return {void}
	*/
	private updateDeliveryInfo(): void {
		this.delivery.address = this.$t('checkout.form.delivery.stadium.formatted_address', { section: this.delivery.section, row: this.delivery.row, seat: this.delivery.seat });
		this.$emit('input', this.delivery);
	}

	/**
	 * Set selectors with options from restaurant config
	 *
	 * @return {void}
	 */
	private setSelectors(): void {
		this.sections = this.restaurantDeliveryInfo.sections.split(',');
		this.rows = this.restaurantDeliveryInfo.rows.split(',');
		this.seats = this.restaurantDeliveryInfo.seats.split(',');
	}

	/**
	 * Scroll to the bottom when opening a selector
	 * 
	 * @param {string} elementId
	 * @return {void}
	 */
	private opened(elementId: string): void {
		setTimeout(() => {
			let contentDiv = document.querySelector(`#${elementId} .vs__dropdown-menu`);
			contentDiv && scrollIntoViewIfNeeded(contentDiv, { behavior: 'smooth', block: 'end', inline: 'start' });
		}, 100);
	}
}
