
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ValidationProvider } from 'vee-validate';
import StarIcon from '../../../assets/images/icons/star.svg?inline';

const namespace: string = 'cart';

@Component<CustomQuestions>({
	components: {
		StarIcon,
		ValidationProvider
	}
})
export default class CustomQuestions extends Vue {
	@Getter('getCheckoutAnsweredQuestions', { namespace }) private checkoutAnsweredQuestions!: CheckoutCustomQuestion[] | null;
	@Getter('getTableNum', { namespace }) private tableNum!: string | null;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean;
	@Prop({ type: Array, required: true, default: false }) private questions!: CustomQuestion[];

	private mutableQuestions: CustomQuestion[] = [];

	private get answeredQuestions(): CheckoutCustomQuestion[] {
		return this.mutableQuestions
			.filter((questionObj) => questionObj.answerObj && Object.keys(questionObj.answerObj).length)
			.map((questionObj) => {
				const index = questionObj.answerObj?.index as number;
				const answeredQuestion: CheckoutCustomQuestion = {
					question: questionObj.question,
					answer: questionObj.options[index]
				};

				// If the question has localization, add the question and answer for each locale
				if (questionObj.localization && Object.keys(questionObj.localization).length) {
					answeredQuestion.localization = {} as Localization;
					for (const locale in questionObj.localization) {
						answeredQuestion.localization[locale] = {
							question: questionObj.localization[locale].question,
							answer: questionObj.localization[locale].options![index]
						}
					}
				}
				return answeredQuestion;
			})
	}

	private mounted(): void {
		this.mutableQuestions = this.questions.map((questionObj: CustomQuestion) => {
			const answeredQuestion: CheckoutCustomQuestion | undefined = this.checkoutAnsweredQuestions?.find((answeredQuestionObj) => answeredQuestionObj.question === questionObj.question);
			const output: CustomQuestion = { ...questionObj };

			// If the question has been already answered, set
			if (answeredQuestion) {
				const index = this.checkoutAnsweredQuestions?.findIndex((answeredQuestionObj) => answeredQuestionObj.question === questionObj.question) as number;
				output.answerObj = {
					index,
					label: this.$options.filters?.localize(answeredQuestion, 'answer')
				}
			}
			return output;
		});
	}

	/**
	 * Return the localized options in an array of objects containing their indexes.
	 * This index is needed to get each localized answer when building the answeredQuestions array.
	 *
	 * @param {CustomQuestion} question
	 * @return {{ index: number; label: string; }[]}
	 */
	private buildOptions(question: CustomQuestion): { index: number; label: string; }[] {
		const localizedOptions: string[] | undefined = this.$options.filters?.localize(question, 'options');
		if (localizedOptions && localizedOptions.length) {
			const output = localizedOptions.map((questionOption: string, index: number) => {
				return {
					index,
					label: questionOption
				};
			});
			return output;
		}
		return question.options.map((questionOption: string, index: number) => {
			return {
				index,
				label: questionOption
			};
		});
	}

	/**
	 * Send event to update the questions' answers to the parent
	 *
	 * @return {void}
	 */
	private updateQuestions(): void {
		this.$emit('input', this.answeredQuestions);
	}
}
