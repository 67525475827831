
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { scrollIntoViewIfNeeded } from '@/utils/helpers';
import '@/validation-rules';

const DEFAULT_DELIVERY_INFORMATION = {
	type: 'school',
	extra: ''
};

@Component<DeliverySchool>({
	components: {
		ValidationProvider
	}
})
export default class DeliverySchool extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private restaurantDeliveryInfo!: SchoolDelivery;
	@Prop({ type: Object, required: true, default: () => {} }) private deliveryInformation!: CheckoutDeliveryInfo;

	private delivery: CheckoutDeliveryInfo = { ...DEFAULT_DELIVERY_INFORMATION };
	private grades: string[] = [];
	private classrooms: string[] = [];

	/**
	* Set grades and classrooms in the selectors
	*
	* @return {void}
	*/
	private created(): void {
		this.grades = this.restaurantDeliveryInfo.grades.split(',');
		this.classrooms = this.restaurantDeliveryInfo.classrooms.split(',');
	}

	private mounted(): void {
		if(this.deliveryInformation) {
			this.delivery = this.deliveryInformation;
			this.delivery.type = 'school';
		}
	}

	/**
	* Send event to the parent to update the delivery
	* information in the vuex store
	*
	* @return {void}
	*/
	private updateDeliveryInfo(): void {
		this.delivery.address = this.$t('checkout.form.delivery.school.formatted_address', { grade: this.delivery.grade, classroom: this.delivery.classroom, student: this.delivery.student });
		this.$emit('input', this.delivery);
	}

	/**
	 * Scroll to the bottom when opening a selector
	 * 
	 * @param {string} elementId
	 * @return {void}
	 */
	private opened(elementId: string): void {
		setTimeout(() => {
			let contentDiv = document.querySelector(`#${elementId} .vs__dropdown-menu`);
			contentDiv && scrollIntoViewIfNeeded(contentDiv, { behavior: 'smooth', block: 'end', inline: 'start' });
		}, 100);
	}
}
