
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Modal from '@/components/shared/Modal.vue';
import InputModalContainer from '@/components/shared/InputModalContainer.vue';
import CartTakeoutOptions from './CartTakeoutOptions.vue';
import CartTipCustomAmount from './CartTipCustomAmount.vue';
import CartDiscount from './CartDiscount.vue';
import CartVoucher from './CartVoucher.vue';
const namespace: string = 'cart';

@Component<CartPricing>({
	components: {
		Modal,
		InputModalContainer,
		CartTakeoutOptions,
		CartTipCustomAmount,
		CartDiscount,
		CartVoucher
	}
})

export default class CartPricing extends Vue {
	@Prop({ type: String, required: true, default: '' }) private subtotal!: string;
	@Prop({ type: String, required: false, default: '' }) private promoDiscount!: string;
	@Prop({ type: String, required: false, default: '' }) private memberDiscount!: string;
	@Prop({ type: String, required: true, default: '' }) private taxes!: string;
	@Prop({ type: String, required: false, default: '' }) private serviceCharge!: string;
	@Prop({ type: String, required: false, default: '' }) private deliveryCharge!: string;
	@Prop({ type: String, required: true, default: '' }) private tip!: string;
	@Prop({ type: String, required: false, default: '' }) private voucherAmount!: string;
	@Prop({ type: String, required: true, default: '' }) private total!: string;
	@Prop({ type: String, required: true, default: '' }) private totalWithTip!: string;
	@Prop({ type: Boolean, required: false, default: false }) private canEditItems!: boolean;
	@Action('updateTip', { namespace }) private updateTip!: (tipObject: any) => void;
	@Action('setCosts', { namespace }) private setCosts!: (specifier: string) => void;
	@Action('setTipSelected', { namespace }) private setTipSelected!: (value: boolean) => void;
	@Getter('getTipObject', { namespace }) private tipObject!: any;
	@Getter('getTipSelected', { namespace }) private tipSelected!: boolean;
	@Getter('isTakeOut', { namespace }) private isTakeOut!: boolean;
	@Getter('isPickupOnly', { namespace }) private isPickupOnly!: boolean;
	@Getter('isDeliveryOnly', { namespace }) private isDeliveryOnly!: boolean;
	@Getter('cartContainsNonAlcoholicItems', { namespace }) private cartContainsNonAlcoholicItems!: boolean;
	@Getter('getServiceChargeLabel', { namespace }) private serviceChargeLabel!: string;
	@Getter('getTaxBrackets', { namespace }) private taxBrackets!: TotalBracketTaxes;
	@Getter('isMarketplaceHub', { namespace: 'restaurant' }) private isMarketplaceHub!: boolean;
	@Getter('getRestaurantDeliveryInfo', { namespace: 'restaurant' }) private restaurantDeliveryInfo!: DefaultDelivery | StadiumDelivery | CustomDelivery;
	@Getter('getRestaurantTipConfig', { namespace: 'restaurant' }) private tipConfig!: TipConfig;
	@Getter('getRestaurantDiscount', { namespace: 'restaurant' }) private restaurantDiscount!: Discount;
	@Getter('getRestaurantVoucher', { namespace: 'restaurant' }) private restaurantVoucherInfo!: object;
	@Getter('isEventDayOrdering', { namespace: 'suites' }) private isEventDayOrdering!: boolean;

	private tipValues: number[] = [15, 18, 20];
	private tipValueSelected: number = 2;
	private tipTitle: string|null = null;
	private inputTip: number = 0;
	private inputType: string = '';
	private opened: boolean = false;
	private settingCosts: boolean = false;
	private hideTipSelection: boolean = false;
	private forceTips: boolean = false;
	private noTips: boolean = false;

	private get displayTaxesLabel(): string {
		if(this.taxBrackets && this.taxBrackets['GST'] && this.taxBrackets['PST']) {
			return `${this.$t('cart.pricing.information.taxes')} (${this.$t('cart.pricing.taxes.gst')} / ${this.$t('cart.pricing.taxes.pst')})`;
		}
		return this.$t('cart.pricing.information.taxes');
	}

	/**
	* Set up tip values if any provided by the restaurant config
	*
	* @return {void}
	*/
	private created(): void {
		if(this.tipConfig) {
			this.tipValueSelected = this.tipConfig.default_value_selected ? this.tipConfig.default_value_selected : 2;
			this.tipValues = this.tipConfig.default_values ? this.tipConfig.default_values : [15, 18, 20];
			this.tipTitle = this.tipConfig.total_summary_title;
		}
	}

	/**
	* Set up the initial tip value if none was clicked
	* and activate the right tip section
	*
	* @return {void}
	*/
	private async mounted(): Promise<void> {
		this.checkToHideOrForceTips();
		if(this.canEditItems) {
			this.selectRespectiveTipBox();
			if(this.tipValueSelected !== 4) {
				this.setTip({ tip: this.tipValues[this.tipValueSelected - 1], type: 'percentage' }, this.tipValueSelected, true);
			}
			await this.getCosts('cartOpened');
		}
	}

	/**
	 * We first check if all the values are equal, if they are it can mean two things:
	 * 1. All tip values are set to 0 so they are disabled by the restaurants,
	 * this means the restaurant do not want to accept tips and we will be removing 
	 * the tip section altogether.
	 * 2. All tip values are set to a certain amount to be enforced by the user. This means
	 * the users cannot edit their selection and are forced to tip with that value.
	 * (IE: Service Charge gratuity)
	 * 
	 * @return {void}
	 */
	private checkToHideOrForceTips(): void {
		if(this.tipValues.every(tipValue => tipValue === this.tipValues[0])) {
			this.hideTipSelection = true;
			if(this.tipValues.every( tipValue => tipValue === 0 )) {
				this.noTips = true;
			}
			else {
				this.forceTips = true;
				this.setTip({tip: this.tipValues[0], type: 'percentage'}, 1);
			}
		}
	}

	/**
	* Select respective box depending on the tip
	* selected
	*
	* @return void
	*/
	// TODO: REFACTOR THIS INTO SUB COMPONENT
	private selectRespectiveTipBox(): void {
		if(this.tipSelected) {
			this.inputType = this.tipObject.type;

			// Display the percentage value and select the respectice box
			if(this.tipObject.type === 'percentage') {
				this.inputTip = this.tipObject.percentageValue;
				if(this.tipObject.percentageValue === this.tipValues[0]) {
					this.tipValueSelected = 1;
				}
				else if(this.tipObject.percentageValue === this.tipValues[1]) {
					this.tipValueSelected = 2;
				}
				else if(this.tipObject.percentageValue === this.tipValues[2]) {
					this.tipValueSelected = 3;
				}
				else {
					this.tipValueSelected = 4;
				}
			}

			// Display dollar amount and select the custom tip box
			else {
				this.inputTip = this.tipObject.amount.toFixed(2);
				this.tipValueSelected = 4;
			}
		}
	}

	/**
	* Calculate the tip value
	*
	* @param {number]} tip
	* @return {string}
	*/
	private calculateTipPercentage(tip: number): string {
		tip = +this.total * (tip / 100);
		return tip.toFixed(2);
	}

	/**
	* Update the tip value in the store depending on which
	* tip box was selected
	*
	* @param {any} tipObject - value & type
	* @param {number} number
	* @return {void}
	*/
	private async setTip(tipObject: TipSelectionObject, id: number, onMounted?: boolean): Promise<void> {
		if(!this.tipSelected) {
			this.setTipSelected(true);
		}
		this.tipValueSelected = id;
		await this.updateTip(tipObject);

		// We are already getting the costs on mounted so no need to do it twice
		if(!onMounted) {
			await this.getCosts('updateTip');
		}
	}

	/**
	 * Get order's costs from the DB
	 *
	 * @param {string} specifier
	 * @return {Promise<void>}
	 */
	private async getCosts(specifier: string): Promise<void> {
		try {
			this.settingCosts = true;
			await this.setCosts(specifier);
		} catch(error) {
			if(typeof error === 'string') {
				this.$toasted.show(error, { type: 'error', position: 'top-center' }).goAway(5000);
			}
			else {
				this.$toasted.show(this.$t('cart.pricing.error_totals'), { type: 'error', position: 'top-center' }).goAway(5000);
			}
		} finally {
			setTimeout(() => {
				this.settingCosts = false;
			}, 150);
		}
	}

	/**
	* Set custom tip value depending on what type was selected
	*
	* @param {any} tipCustomObject - value & type
	*/
	private setCustomTip(tipCustomObject: any): void {
		this.inputType = tipCustomObject.type;
		this.inputTip = this.inputType === 'percentage' ? tipCustomObject.tip : tipCustomObject.tip.toFixed(2);
		this.setTip(tipCustomObject, 4);
	}

	/**
	* Hide the input modal
	*
	* @return {void}
	*/
	private hideInputModal(): void {
		this.opened = false;
	}
}
